.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #ececec;
  min-height: 100vh;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.mainMenu{
  border-radius: 15px;
  padding: 7px;
  background: #fff;
  margin: 15px;
}

.mainMenu a{
  color: #000;
  font-weight: bold;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.mainMenu a:hover{
  color: #fc8d29;
  font-weight: bold;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.mainMenu a.active{
  color: #fc8d29;
  border-bottom: 2px solid #fc8d29;
  font-weight: bold;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
}

.mycustomSearch{
  background-color: #ececec;
  border-radius: 99999px;
  width: 200px;
  padding: 5px 17px;
  border: none;
  box-shadow: none;
}

.mycustomSearch:focus, .mycustomSearch:active{
  background: #ececec;
  border-radius: 99999px;
  width: 200px;
  padding: 5px 17px;
  border: none;
  box-shadow: none;
}

.mycustomSearch2{
  background-color: #fff;
  border-radius: 99999px;
  width: 300px;
  padding: 5px 17px;
  border: none;
  box-shadow: none;
}

.mycustomSearch2:focus, .mycustomSearch2:active{
  background: #fff;
  border-radius: 99999px;
  width: 300px;
  padding: 5px 17px;
  border: none;
  box-shadow: none;
}

.divider {
  position: absolute;
  left: 50%;
  top: 10%;
  bottom: 40%;
  border-left: 1px solid #282c34;
}

.card{
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 10px #ccc;
}

.btn-warning, .bg-warning{
  background: #FE9F35;
  color: #fff;
}

.btn-primary, .bg-primary{
  background: #0093ff;
  color: #fff;
}

.text-primary{
  color: #0093ff!important;
}

.text-warning{
  color: #FE9F35;
}

.text-danger{
  color: #ff0000;
}

.btn-danger, .bg-danger{
  background: #ff0000;
  color: #fff;
}

.btn-success, .bg-success{
  background: #41CE3B;
  color: #fff;
}

.text-success{
  color: #41CE3B;
}

.btn-info, .bg-info{
  background: #C8E7FD;
  color: #fff;
}

.text-info{
  color: #C8E7FD;
}

.text-mutted{
  color: #7f7f7f;
}

.btn-mutted{
  background: #fff;
  border: 1px solid #7f7f7f;
  color: #7f7f7f;
}

.nav-item a{
  text-decoration: none;
}

.modal-xl.modal-dialog{
  max-width: 100%;
  max-height: 90vh;
  height: 90vh;
}

.my-gallery-class{
  list-style: none;
}

.myBg{
  background-size: cover!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
}

.mycontainer {
  position: relative;
  width: 96%;
  height: 0;
  margin-left: 2%;
  padding-bottom: 56.25%;
  text-align: center;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circular--square { border-radius: 50%; }