.loginPage{
    height: 100vh;
    padding-top: 15vh;
}

.loginPage .container, .loginPage .row{
    height: 100%;
}

.loginPresentation{
    background: linear-gradient(90deg, rgb(41, 38, 90) 0%,  rgb(248,249,254) 100%);
}

.mycustomInput{
    background: #ececec!important;
    border-radius: 99999px!important;
    padding: 5px 17px!important;
    border: none!important;
    box-shadow: none!important;
}
.mycustomInput:active, .mycustomInput:focus{
    background: #ececec!important;
    border-radius: 99999px!important;
    padding: 5px 17px!important;
    border: none!important;
    box-shadow: none!important;
}

.myCustomButton {
    border-radius: 99999px!important;
}

.logo-img{
    width: 150px!important;
    max-height: 80px!important;
    height: 80px!important;
}
